import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import Img from 'gatsby-image';
import Layout from '../../components/shared/layout';
import SEO from '../../components/SEO/SEO';
import { wrapper, breakpoints, title, ContentStyles, border, color } from '../../utils/style';
import Breadcrumbs from '../../components/shared/Breadcrumbs';

const FeaturedWrapper = styled('div')`
  user-select: none;
  position: relative;
  .breadcrumbs {
    position: absolute;
    top: 20px;
    left: 30px;
    z-index: 1;
  }
  ${title};
  .gatsby-image-wrapper {
    border-radius: ${border.borderRadius4};
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: ${border.borderRadius4};
  }
  
  @media (max-width: ${breakpoints.md}) {
    & .gatsby-image-wrapper {
      height: 140px;
    }
  
    .breadcrumbs {
      top: 16px;
      left: 16px;
    }
    
    h1 {
      top: 50px;
      left: 16px;
      
      font-size: 24px;
      text-shadow: unset;
      -webkit-text-stroke: unset;
      color: ${color.lightningYellow};
    }
  }
`;

const NoFeaturedWrapper = styled('div')`
  .breadcrumbs,
  h1 {
    position: static;
    margin: 0;
  }

  @media (max-width: ${breakpoints.sm}) {
    .breadcrumbs,
    h1 {
      margin: 0 0 20px;
    }
  }
`;

const WrapperDesktop = styled('div')``;

const ContentWrapper = styled('div')`
  ${wrapper};
  ${ContentStyles};
  
  @media (max-width: ${breakpoints.md}) {
    margin-top: 20px;
  }
`;

// REFACTOR
// Spread and logic but at the first check if it's not useless not. As i think that's component can be never fired.
const Category = ({ data, pageContext }) => {
  const currentCategory = data.allWordpressCategory.edges[0].node;
  const metaTitle =
    currentCategory.yoast_meta && currentCategory.yoast_meta.yoast_wpseo_title
      ? currentCategory.yoast_meta.yoast_wpseo_title
      : `${currentCategory.title}- ${process.env.OWNER}`;
  const metaDesc =
    currentCategory.yoast_meta && currentCategory.yoast_meta.yoast_wpseo_metadesc
      ? currentCategory.yoast_meta.yoast_wpseo_metadesc
      : '';
  const { breadcrumbs } = pageContext;
  const { page_title } = pageContext;

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDesc}
        pathname={currentCategory.path.replace('category/', '')}
        key="seo"
        breadcrumbs={breadcrumbs}
      />
      <WrapperDesktop>
        {currentCategory.acf && currentCategory.acf.category_image && currentCategory.acf.category_image.localFile ? (
          <FeaturedWrapper>
            <Breadcrumbs breadcrumbs={breadcrumbs} page_title={page_title} />
            <Img
              fluid={currentCategory.acf.category_image.localFile.childImageSharp.fluid}
              alt={
                currentCategory.acf.category_image.alt_text
                  ? currentCategory.acf.category_image.alt_text
                  : currentCategory.name
              }
            />
            <h1 dangerouslySetInnerHTML={{ __html: currentCategory.name }} />
          </FeaturedWrapper>
        ) : (
          <NoFeaturedWrapper>
            <Breadcrumbs breadcrumbs={breadcrumbs} page_title={page_title} />
            <h1 dangerouslySetInnerHTML={{ __html: currentCategory.name }} />
          </NoFeaturedWrapper>
        )}
        {currentCategory.description && (
          <ContentWrapper>
            <div dangerouslySetInnerHTML={{ __html: currentCategory.description }} />
          </ContentWrapper>
        )}
      </WrapperDesktop>
    </Layout>
  );
};

export default Category;

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    allWordpressCategory(filter: { wordpress_id: { eq: $wordpress_id } }) {
      edges {
        node {
          id
          name
          slug
          description
          path
          wordpress_id
          link
          acf {
            category_image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1400) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          yoast_meta {
            yoast_wpseo_title
            yoast_wpseo_metadesc
          }
        }
      }
    }
    allWordpressPost(
      sort: { fields: date, order: DESC }
      filter: { categories: { elemMatch: { wordpress_id: { eq: $wordpress_id } } } }
    ) {
      edges {
        node {
          title
          excerpt
          slug
          featured_media {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    placeholder: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
